/* eslint-disable no-alert */
/* eslint-disable arrow-body-style */
import { useEffect } from 'react';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { Navigate, Route, Routes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Login from './pages/Login';

// ----------------------------------------------------------------------

export default function Router() {
  const auth = useAuth();

  useEffect(() => {
    console.log(`starting app env: ${process.env.REACT_APP_CLIENT_ID}`);
    if (auth.user) console.log('user exists');
    else console.log('user does not exist');
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      console.log('sign in redirect');
      auth.signinRedirect();
    }
    return auth.events.addAccessTokenExpiring(() => {
      if (alert('Budete odhlásený z dôvodu neaktivity. Kliknite na tlačidlo OK ak chcete zostať prihlásený.')) {
        auth.signinSilent();
      }
    });
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
    auth.events,
    auth.signinSilent,
    auth,
  ]);

  // eslint-disable-next-line default-case
  if (auth.activeNavigator) {
    switch (auth.activeNavigator) {
      case 'signinSilent':
        return <div>Signing you in...</div>;
      case 'signoutRedirect':
        return <div>Signing you out...</div>;
      default:
        return <div>loading</div>;
    }
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }
  if (auth.isAuthenticated) {
    return (
      <Routes>
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index path="app" element={<DashboardApp />} />
          <Route index path="user" element={<User />} />
          <Route index path="products" element={<Products />} />
          <Route index path="blog" element={<Blog />} />
        </Route>
        <Route path="/" element={<LogoOnlyLayout />}>
          <Route path="/" element={<Navigate to="/dashboard/app" />} />
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Route>
        <Route path="/registracia" element={<Register />} />
        <Route path="/prihlasenie" element={<Login />} />
      </Routes>
    );
  }
}

// const AuthRoute = ({ children }) => {
//   const auth = useAuth();
//   // if (!auth.isAuthenticated) {
//   //   // return <Navigate to="/login" />;
//   //   return auth.signinRedirect();
//   // }
//   return children;
// };

// return useRoutes([
//   {
//     path: '/dashboard',
//     element: <AuthRoute children={<DashboardLayout />} />,
//     children: [
//       { path: 'app', element: <DashboardApp /> },
//       { path: 'user', element: <User /> },
//       { path: 'products', element: <Products /> },
//       { path: 'blog', element: <Blog /> },
//     ],
//   },
//   {
//     path: 'login',
//     element: <Login />,
//   },
//   {
//     path: 'register',
//     element: <Register />,
//   },
//   {
//     path: '/',
//     element: <LogoOnlyLayout />,
//     children: [
//       { path: '/', element: <Navigate to="/dashboard/app" /> },
//       { path: '404', element: <NotFound /> },
//       { path: '*', element: <Navigate to="/404" /> },
//     ],
//   },
//   {
//     path: '*',
//     element: <Navigate to="/404" replace />,
//   },
// ]);
