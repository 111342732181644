/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
import { User } from 'oidc-client-ts';

function getUser() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export async function client(endpoint, { body, ...customConfig } = {}) {
  const user = getUser();
  const token = user?.access_token;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const response = await window.fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, { headers });
  const data = await response.json();
  if (response.ok) {
    return data;
  }
  return null;
}

client.get = function (endpoint) {
  return client(endpoint, { method: 'GET' });
};

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body });
};
