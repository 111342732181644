import { format, formatDistanceToNow } from 'date-fns';
import { sk } from 'date-fns/locale';

// ----------------------------------------------------------------------

export function fDate(date) {
  // eslint-disable-next-line no-undef
  return format(new Date(date), 'dd MMMM yyyy', { locale: sk });
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
